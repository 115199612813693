import {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {QSVerify, QSVerifyGroup} from '../../modules/api/requests/QuotationRequest'
import {QSVerifyTable} from '../../modules/widgets/quotation/QSVerifyTable'
import {ReturnNull} from '../../modules/widgets/func/ReturnNull'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {VerifyGroup} from '../../modules/widgets/quotation/verify/group'
import {VerifyAddGroup} from '../../modules/widgets/quotation/verify/AddGroup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faObjectGroup} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import {BGLoad} from '../../modules/widgets/func/BackgroundLoading'

const QSForm = () => {
  const [columns, setColumn] = useState([])
  const [qs, setQS] = useState([])
  const [dataset, setDataset] = useState([])
  const [checked, setChecked] = useState(false)

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  const [group_by, setGroupBy] = useState('group')
  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(0)
  const [filters, setFilters] = useState('')

  const fetchAPI = async ({limit, skip, search, status, groupby}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      let qs_status: any = encodeURIComponent(JSON.stringify(status || null))
      let group_by: any = encodeURIComponent(JSON.stringify(groupby || 'group'))
      const res: any = await QSVerify(lt, sk, sr, qs_status, group_by)
      let data: any = res.data.data
      let count: number = res.data.count
      count = count / 1
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter, status, groupby}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    setSize(pageSize)
    setIndex(pageIndex)
    setFilters(filter)
    status = status
    setGroupBy(groupby)
    if (fetchId == fetchIdRef.current) {
      fetchAPI({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search: filter,
        status: status,
        groupby: groupby,
      })
    }
  }, [])

  const getData = () => {
    fetchAPI({
      limit: size || 10,
      skip: size * index || 0,
      search: filters || '',
      status: '',
      groupby: group_by || 'group',
    })
  }

  const getItem = (id: number, bool: boolean) => {
    let newdata: any = []
    newdata = dataset
    if (newdata.length > 0) {
      if (bool == true) {
        if (newdata.includes(id) == false) {
          newdata.push(id)
          setDataset(newdata)
        }
      } else {
        if (newdata.includes(id) == true) {
          let index = newdata.findIndex((val: number) => val == id)
          if (index > -1) {
            newdata.splice(index, 1)
            setDataset(newdata)
          }
        }
      }
    } else {
      newdata.push(id)
      setDataset(newdata)
    }
  }

  const setGroup = async () => {
    if (dataset.length > 0) {
      Swal.fire({
        title: 'Are you confirm to set group?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSVerifyGroup(dataset)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful set group!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  })
                  resetData()
                } else {
                  Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    heightAuto: false,
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'Please select the data to group.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  // set colomn
  const getColumn = () => {
    let table: any = [
      {
        Header: 'From User',
        headerClassName: 'bg-light-warning min-w-50px',
        columns: [
          {
            Header: 'Select',
            id: 'index',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => (
              <>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={'item_' + cell.row.original.id}
                  onChange={(e) => {
                    getItem(cell.row.original.id, e.target.checked)
                    getColumn()
                  }}
                  checked={
                    dataset ? dataset.some((val: any) => val === cell.row.original.id) : false
                  }
                  disabled={
                    cell.row.original.status == 'processing' ||
                    cell.row.original.status == 'finished'
                      ? true
                      : false
                  }
                />
              </>
            ),
          },
          {
            Header: 'Sect.',
            id: 'sect',
            headerClassName: 'bg-light-warning min-w-150px',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.dept_name)}</>,
          },
          {
            Header: <>Group&nbsp;Item</>,
            id: 'item_group',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.item_group_title)}</>,
          },
          {
            Header: 'Item',
            id: 'item',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => (
              <>{cell.row.original.item_title || cell.row.original.item_other}</>
            ),
          },
          {
            Header: 'Qty',
            id: 'qty',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.qty)}</>,
          },
          {
            Header: 'Unit',
            id: 'unit',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.unit_title)}</>,
          },
          {
            Header: 'Supplier',
            id: 'supplier',
            headerClassName: 'bg-light-warning',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.supplier_title)}</>,
          },
        ],
      },
      {
        Header: 'Form Systems',
        headerClassName: 'bg-light-dark',
        columns: [
          {
            Header: 'Supplier',
            id: 'fs_supplier',
            headerClassName: 'bg-light-dark',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.form_system_sup)}</>,
          },
          {
            Header: 'Group',
            id: 'fs_group',
            headerClassName: 'bg-light-dark',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.form_system_sg)}</>,
          },
          {
            Header: 'Price',
            id: 'fs_price',
            headerClassName: 'bg-light-dark',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.form_system_price)}</>,
          },
          {
            Header: 'Unit',
            id: 'fs_unit',
            headerClassName: 'bg-light-dark',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.form_system_unit)}</>,
          },
          {
            Header: 'Total',
            id: 'fs_total',
            headerClassName: 'bg-light-dark',
            Cell: ({cell}: any) => (
              <>
                {(cell.row.original.form_system_price || 0) *
                  (cell.row.original.form_system_qty || 0)}
              </>
            ),
          },
        ],
      },
      {
        Header: 'Quotation',
        headerClassName: 'bg-light-danger',
        columns: [
          {
            Header: 'Status',
            id: 'status',
            headerClassName: 'bg-light-danger',
            Cell: ({cell}: any) => <>{statusFormat(cell.row.original.status)}</>,
          },
          {
            Header: 'Job no',
            id: 'job_no',
            headerClassName: 'bg-light-danger',
            Cell: ({cell}: any) => <>{ReturnNull(cell.row.original.job_number)}</>,
          },
          {
            Header: 'Action',
            id: 'action',
            headerClassName: 'bg-light-danger',
            Cell: ({cell}: any) => {
              if (cell.row.original.status === 'submit') {
                return ''
              } else {
                return (
                  <VerifyGroup
                    id={cell.row.original.job_no}
                    resetData={resetData}
                    setDisabled={false}
                  />
                )
              }
            },
          },
        ],
      },
    ]
    setColumn(table)
  }

  const resetData = () => {
    setDataset([])
    getData()
    getColumn()
  }

  const AddGroup = <VerifyAddGroup data={dataset} resetData={resetData} />

  useEffect(() => {
    getColumn()
  }, [dataset, group_by, index, size, filters])

  return (
    <>
      <QSVerifyTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        addGroup={AddGroup}
        setGroup={setGroup}
        setDataset={setDataset}
      />
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const QSVerificationList = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Verification List</PageTitle>
      <QSForm />
      <BGLoad />
    </>
  )
}

export default QSVerificationList
